import React from "react";

const OngoingProject = () => {
  return (
    <>
      <div className="text-center pt-4 about-hea text-light bg-light">
        <h1 className="theme-color">Our Ongoing Projects</h1>
        <div className="redproject-line"></div>
      </div>
      <div className="container-fluid bg-light pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="city-box">
                <img
                  className="city-image rounded shadow-lg border p-2"
                  src="images/jaipur.webp"
                  alt="jaipur"
                />
                <div class="middle">
                  <div class="city-name text-uppercase">Jaipur</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="city-box">
                <img
                  className="city-image rounded shadow-lg border p-2"
                  src="images/ujjain.jpg"
                  alt="ujjain"
                />
                <div class="middle">
                  <div class="city-name text-uppercase">Ujjain</div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="city-box">
                <img
                  className="city-image rounded shadow-lg border p-2"
                  src="images/indore.jpg"
                  alt="indore"
                />
                <div class="middle">
                  <div class="city-name text-uppercase">indore</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="city-box">
                <img
                  className="city-image rounded shadow-lg border p-2"
                  src="images/mau.jpg"
                  alt="mau"
                />
                <div class="middle">
                  <div class="city-name text-uppercase">mau</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingProject;
