import { createContext, useContext, useState, useEffect } from "react";
const CanonicalContext = createContext();
export const CanonicalProvider = ({ children }) => {
  const [canonicalUrl, setCanonicalUrl] = useState(
    "https://maliaconstruction.com"
  );

  useEffect(() => {
    // Update the canonical tag in the head when canonicalUrl changes
    const existingCanonicalTag = document.querySelector(
      'link[rel="canonical"]'
    );

    if (existingCanonicalTag) {
      existingCanonicalTag.setAttribute("href", canonicalUrl);
    } else {
      // If the canonical tag doesn't exist, create and append it to the head
      const newCanonicalTag = document.createElement("link");
      newCanonicalTag.setAttribute("rel", "canonical");
      newCanonicalTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newCanonicalTag);
    }
    return () => {
      const canonicalTag = document.querySelector('link[rel="canonical"]');
      if (canonicalTag) {
        document.head.removeChild(canonicalTag);
      }
    };
  }, [canonicalUrl]);

  const updateCanonicalUrl = (newUrl) => {
    setCanonicalUrl(newUrl);
  };

  return (
    <CanonicalContext.Provider value={{ canonicalUrl, updateCanonicalUrl }}>
      {children}
    </CanonicalContext.Provider>
  );
};

export const useCanonicalContext = () => {
  return useContext(CanonicalContext);
};
