import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Whatsapp from "./Whatsapp";
import AOS from "aos";
import "aos/dist/aos.css";
import { useCanonicalContext } from "./CanonicalContext";

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { canonicalUrl, updateCanonicalUrl } = useCanonicalContext();
  useEffect(() => {
    updateCanonicalUrl("https://maliaconstruction.com/services");
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="about-banner">
          <div className="about-banner-img"></div>
          <div className="about-banner-text">Our Services</div>
        </div>
      </div>

      <div className="container-fluid bg-light p-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>
                Transforming Visions into Solid Structures: Comprehensive Civil
                Construction Services
              </h1>
              <h2>1: Unleashing Expertise in Erection Services</h2>
              <p>
                Embark on your construction journey with our unmatched expertise
                in erection services. As a leading name in civil construction,
                we specialize in seamlessly assembling the structural framework
                of your projects. Our experienced team ensures precision,
                efficiency, and adherence to safety standards, laying the
                foundation for the success of your venture. From residential
                complexes to commercial spaces, trust us for reliable and expert
                erection services that bring your architectural vision to life.
              </p>
              <h2>2: Perfecting the Art of Plaster Application</h2>
              <p>
                Witness the meticulous craft of plaster application that defines
                our civil construction services. Our skilled artisans blend
                traditional craftsmanship with modern techniques to create
                smooth, durable, and aesthetically pleasing plaster finishes.
                From interior walls to exterior facades, we prioritize quality
                and attention to detail, ensuring that your spaces exude a
                polished and refined look. Elevate your projects with our
                expertise in plaster application that stands the test of time.
              </p>
            </div>
            <div className="col-lg-6">
              <div>
                <img
                  className="shadow rounded border service-image1"
                  src="images/plaster.jpg"
                  alt="malia construction"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <h2>3: Flooring Excellence for Solid Foundations</h2>
          <p>
            Step into a world of flooring excellence as we bring innovation and
            precision to every project. Whether it's sleek tiles, elegant
            hardwood, or durable concrete, our civil construction services cover
            a spectrum of flooring options. We tailor solutions to match your
            design preferences, ensuring not just beauty but also functionality.
            Trust us to lay the groundwork for stunning and durable floors that
            set the stage for the success of your residential, commercial, or
            industrial spaces.
          </p>
        </div>
      </div>

      <div className="container py-3">
        <div className="row">
          <div className="col-lg-6">
            <div>
              <img
                className="shadow rounded border service-image2"
                src="images/structural.jpg"
                alt="malia construction"
                width="100%"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>4: Structural Integrity as a Hallmark</h2>
            <p>
              At the core of our civil construction services is a commitment to
              structural integrity. Our team of engineers and builders
              collaborates seamlessly to ensure that the structural framework of
              your project is robust and meets the highest safety standards.
              From design conceptualization to implementation, we prioritize
              precision and durability, providing you with structures that
              withstand the test of time. Your vision is our mission, and we
              build it with a foundation rooted in structural excellence.
            </p>
            <h2>5: Green Wash Solutions for Sustainable Construction</h2>
            <p>
              Embrace sustainable construction practices with our innovative
              greenwash solutions. We understand the importance of minimizing
              environmental impact without compromising on quality. Our green
              wash services utilize eco-friendly materials and techniques,
              promoting energy efficiency and reducing the carbon footprint of
              your project. Join us in building a greener future as we
              incorporate sustainable practices into every aspect of our civil
              construction services, from design to execution.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light p-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>6: Artistry in Tiling for Aesthetic Brilliance</h2>
              <p>
                Experience the artistry in tiling that adds a touch of aesthetic
                brilliance to your spaces. Our civil construction services
                include expert tiling solutions that range from classic ceramics
                to contemporary mosaic patterns. Whether it's kitchens,
                bathrooms, or decorative features, our skilled craftsmen bring
                creativity and precision to every tile laid. Elevate your
                interiors with our tiling expertise, where art meets
                functionality, and each tile contributes to the overall beauty
                of your residential or commercial space.
              </p>
              <h2>
                Conclusion: Elevate Your Construction Experience with Our
                Comprehensive Services
              </h2>
              <p>
                In the realm of civil construction, we are not just builders; we
                are creators of enduring and inspiring spaces. Our comprehensive
                range of services, from expert erection and plaster application
                to flooring excellence, structural integrity, green wash
                solutions, and artistry in tiling, reflects our commitment to
                excellence. When you choose us, you choose a partner dedicated
                to turning your visions into solid, aesthetically pleasing, and
                sustainable structures. Experience the difference between
                construction services that go beyond the ordinary – where
                expertise meets innovation, and your dreams take shape with
                unwavering precision.
              </p>
            </div>
            <div className="col-lg-6">
              <div>
                <img
                  className="shadow rounded border service-image1"
                  src="images/tilling.jpg"
                  alt="malia construction"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Whatsapp />
      <Footer />
    </>
  );
};

export default Services;
